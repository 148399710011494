jQuery(document).ready(function($){

/* ----------------------- Touch Swipe ---------------------- */
    $("[data-open]").on('click', function() {
        var add_el = $(this).data("open");
        $(add_el).toggleClass("open-menu");
        $('.button-menu-open').toggleClass("closed");
    });
/* --------------------- End Touch Swipe -------------------- */


/* --------------- Deleting placeholder focus --------------- */
    $('input,textarea').focus(function(){
        $(this).data('placeholder',$(this).attr('placeholder'))
        $(this).attr('placeholder','');
    });
    $('input,textarea').blur(function(){
        $(this).attr('placeholder',$(this).data('placeholder'));
    });
/* ------------- End Deleting placeholder focus ------------- */


});